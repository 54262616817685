* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.loading.cover-content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loading.cover-page {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading .ant-spin {
    color: #EA3557;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input[type=number] {
    -moz-appearance: textfield;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.btnFile {
    width: 80px;
    height: 30px;
    background: #EA3557;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
}

.uploadTitle {
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
}

.btnDanger {
    color: white !important;
    background-color: #ea3557 !important;
}

.btnDanger:hover {
    background-color: #ea3557 !important;
    color: #fff !important;
    opacity: 0.8;
}

.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
    color: #ea3557 !important;
}

.ant-menu-inline .ant-menu-item::after, .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after {
    border-right: 3px solid #EA3557 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffc9d3 !important;
}

.ant-menu-item .ant-menu-item-active {
    color: #EA3557 !important;
}

.ant-menu-item:hover a, .ant-menu-item > .ant-badge a {
    color: #EA3557 !important;
}

.ant-menu-item-focused {
    background-color: #ffc9d3 !important;
    color: #EA3557 !important;
}

.ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-submenu-title:hover, ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #EA3557 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #EA3557 !important;
}

.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
    box-shadow: 0 0 0 2px #ffc9d3 !important;
    border-color: #EA3557 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #EA3557 !important;
    box-shadow: 0 0 0 2px #ffc9d3 !important;
}

.ant-select-selector:hover {
    border-color: #EA3557 !important;
}

.ant-select-selector:focus {
    box-shadow: 0 0 0 2px #ffc9d3 !important;
}

.ant-select-item-option-active {
    background-color: #ffc9d3 !important;
    color: #EA3557 !important;
}

.ant-input-affix-wrapper-focused {
    border: 1px solid #EA3557 !important;
    box-shadow: 0 0 0 2px #ffc9d3 !important;
}

.ant-select-item-option-active:hover {
    background-color: #ffc9d3 !important;
    color: #EA3557 !important;
}

.ant-input:hover {
    border: 1px solid #EA3557 !important;
}

.ant-input:focus {
    border: 1px solid #EA3557 !important;
    box-shadow: 0 0 0 2px #ffc9d3 !important;
}

.ant-picker:hover {
    border: 1px solid #EA3557 !important;
}

input[placeholder="Search"]:hover,
input[type="password"]:hover,
input[id="register-form_email"]:hover,
input[id="login-form_email"]:hover {
    border: none !important;
    box-shadow: none !important;
}

input[placeholder="Search"]:focus,
input[type="password"]:focus,
input[id="register-form_email"]:focus,
input[id="login-form_email"]:focus {
    border: none !important;
}

.ant-picker-focused {
    box-shadow: 0 0 0 2px #ffc9d3 !important;
    color: #EA3557 !important;
}

.ant-picker-today-btn {
    color: #EA3557 !important;
}

.nav-left:hover svg {
    color: #EA3557 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #EA3557 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today {
    color: #EA3557 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #EA3557 !important;
}

.ant-pagination-item-active {
    background-color: #EA3557 !important;
}

.ant-pagination .ant-pagination-item:hover a {
    color: #ffc9d3 !important;
}

::selection {
    background-color: #ffc9d3 !important;
}

.ant-pagination-next:hover .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link {
    border: 1px solid #EA3557 !important;
}

.ant-pagination-next:hover .ant-pagination-item-link svg, .ant-pagination-prev:hover .ant-pagination-item-link svg {
    color: #EA3557 !important;
}
